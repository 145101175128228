.finder-container {
    .label-container {
        margin-left: 8px;
        padding: 0 5px;
        background: white;
        position: absolute;
        z-index: 1000;
        top: -13px;
    }

    .ant-form-item {
        margin-bottom: 20px;
    }

    .ant-picker,
    .ant-select,
    .ant-input {
        border: 1px solid #465968;
        border-radius: 0;
    }

    .ant-picker,
    .ant-input {
        height: 34px;
    }

    .ant-btn {
        &.ant-btn-primary {
            background: #00a86b;
            border-color: #00a86b;
            border-radius: 4px;
            height: auto;
            padding: 10px 40px;
            font-weight: 600;
            box-shadow: 3px 3px 6px rgb(75, 75, 75);
        }
    }

    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        background: transparent;
    }
}
