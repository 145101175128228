.home-page {
    padding-bottom: 4rem;

    h1 {
        line-height: 1.15em;

        @media (max-width: 768px) {
            font-size: 38px;
        }
    }

    h2 {
        @media (max-width: 450px) {
            font-size: 24px;
        }
    }
}
