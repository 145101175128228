.vehicle-finder-response-container {
    margin-top: 3rem;
    border: 4px solid #465968;
    width: 100%;
    padding: 2rem;
    position: relative;

    .finder-headings {
        strong {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            display: none;
        }
    }

    .found-vehicle {
        margin-top: 0.5rem;

        .small-label {
            display: none;
        }

        .ant-btn {
            &.ant-btn-primary {
                background: #00a86b;
                border-color: #00a86b;
                border-radius: 4px;
                height: auto;
                font-weight: 600;
                box-shadow: 3px 3px 6px rgb(75, 75, 75);
            }
        }

        @media (max-width: 768px) {
            border: 1px solid #afafaf;
            padding: 10px;

            .small-label {
                display: inline;
                color: #777777;
            }
        }
    }

    .vehicle-info {
        margin-top: 3rem;
        font-family: Mukta Vaani;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        letter-spacing: 0.2px;
        color: #465968;

        @media (max-width: 768px) {
            margin-top: 1rem;
            font-size: 16px;
        }
    }

    .results-title {
        background-color: #465968;
        text-transform: uppercase;
        color: #fff;
        font-size: 1.5rem;
        width: 130px;
        position: absolute;
        top: -40px;
        left: 20px;
        text-align: center;
        font-weight: 500;
    }
}
