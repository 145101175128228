.informationals {
    margin-bottom: 4rem;

    @media (max-width: 450px) {
        margin-bottom: 2rem;
    }

    .selection-box {
        width: 100%;
        background-color: #00a86b;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        box-shadow: 3px 3px 6px rgb(75, 75, 75);

        @media (max-width: 1200px) {
            margin-bottom: 1rem;
        }

        h3 {
            color: white;
        }

        .selection-icon-container {
            width: 107px;
            height: 107px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1258px) {
                width: 90px;
                height: 90px;
            }

            @media (max-width: 450px) {
                width: 75px;
                height: 75px;
            }

            .selection-icon {
                height: 100%;
            }
        }
    }
}

.information-body {
    .information-container {
        border: 4px solid rgba(70, 89, 104, 0.7);
        padding: 1rem;

        &:not(:first-child) {
            margin-top: 2rem;
        }

        p {
            font-family: Mukta Vaani;
            font-weight: normal;
            font-size: 20px;
        }
    }
}

.ant-modal-header {
    background: #465968;

    .ant-modal-title {
        color: white;
        font-family: Mukta Vaani;
        font-weight: 600;
        font-size: 48px;
        line-height: 1em;
    }
}

.ant-modal-close-x {
    .anticon {
        font-size: 36px;
        color: white;
        vertical-align: -0.35em;
    }
}
